import {ReactNode, createContext, useContext, useMemo} from 'react';
import {ThemeProvider as StyledThemeProvider} from 'styled-components';
import {UserThemeUseCase} from '@Domain/UseCases/UserTheme';
import theme from '@ds/components/bosons/Theme';

export type ThemeProvider = {
  children: ReactNode;
  useCase: UserThemeUseCase;
};

const Theme = createContext<{
  userTheme: 'light' | 'dark';
  toggleTheme: () => void;
}>({
  userTheme: 'light',
  toggleTheme: () => {},
});

export function useTheme() {
  return useContext(Theme);
}

export default (props: ThemeProvider) => {
  const {children, useCase} = props;
  const {userTheme, toggleTheme} = useCase;

  return useMemo(
    () => (
      <Theme.Provider
        value={{
          userTheme,
          toggleTheme,
        }}>
        <StyledThemeProvider theme={theme[userTheme]}>{children}</StyledThemeProvider>
      </Theme.Provider>
    ),
    [children, userTheme],
  );
};
