import { AxiosProgressEvent } from 'axios';
import { BehaviorSubject } from 'rxjs';

export type HttpRequest = {
  url: string,
  method?: HttpMethod,
  body?: any,
  headers?: any
  observe?: string;
  responseType?: any;
  timeout?: number;
  withCredentials?: boolean;
  onUploadProgress?: ((progressEvent: AxiosProgressEvent) => void) | undefined;
  signal?: AbortSignal;
};

export interface HttpClient<R = any> {
  get: (data: HttpRequest) => Promise<HttpResponse<R>>,
  getStream: <t>(data: HttpRequest) => BehaviorSubject<t>,
  post: (data: HttpRequest) => Promise<HttpResponse<R>>,
  postStream: <t>(data: HttpRequest) => BehaviorSubject<t>,
  put: (data: HttpRequest) => Promise<HttpResponse<R>>,
  patch: (data: HttpRequest) => Promise<HttpResponse<R>>,
  delete: (data: HttpRequest) => Promise<HttpResponse<R>>
};

export enum HttpMethods {
  get = 'GET',
  patch = 'PATCH',
  post = 'POST',
  put = 'PUT',
  delete = 'DELETE'
};

export type HttpMethod = HttpMethods.get | HttpMethods.post | HttpMethods.put | HttpMethods.patch | HttpMethods.delete;

export enum HttpStatusCode {
  ok = 200,
  created = 201,
  accepted = 202,
  noContent = 204,
  badRequest = 400,
  unauthorized = 401,
  forbidden = 403,
  notFound = 404,
  serverError = 500,
  serverTimeout = 504
};

export type HttpResponse<T = any> = {
  statusCode: HttpStatusCode;
  body?: T;
  headers?: any;
};
