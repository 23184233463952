import {createGlobalStyle} from 'styled-components';

const GlobalStyle = createGlobalStyle`
  /* cyrillic-ext */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://smartplayer.scaleup.com.br/fonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7W0Q5n-wU.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://smartplayer.scaleup.com.br/fonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7W0Q5n-wU.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://smartplayer.scaleup.com.br/fonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7W0Q5n-wU.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://smartplayer.scaleup.com.br/fonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7W0Q5n-wU.woff2) format('woff2');
    unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://smartplayer.scaleup.com.br/fonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7W0Q5n-wU.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://smartplayer.scaleup.com.br/fonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7W0Q5n-wU.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://smartplayer.scaleup.com.br/fonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* cyrillic-ext */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://smartplayer.scaleup.com.br/fonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7W0Q5n-wU.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://smartplayer.scaleup.com.br/fonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7W0Q5n-wU.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://smartplayer.scaleup.com.br/fonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7W0Q5n-wU.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://smartplayer.scaleup.com.br/fonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7W0Q5n-wU.woff2) format('woff2');
    unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://smartplayer.scaleup.com.br/fonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7W0Q5n-wU.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://smartplayer.scaleup.com.br/fonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7W0Q5n-wU.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://smartplayer.scaleup.com.br/fonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* cyrillic-ext */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://smartplayer.scaleup.com.br/fonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7W0Q5n-wU.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://smartplayer.scaleup.com.br/fonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7W0Q5n-wU.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://smartplayer.scaleup.com.br/fonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7W0Q5n-wU.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://smartplayer.scaleup.com.br/fonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7W0Q5n-wU.woff2) format('woff2');
    unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://smartplayer.scaleup.com.br/fonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7W0Q5n-wU.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://smartplayer.scaleup.com.br/fonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7W0Q5n-wU.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://smartplayer.scaleup.com.br/fonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* cyrillic-ext */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://smartplayer.scaleup.com.br/fonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7W0Q5n-wU.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://smartplayer.scaleup.com.br/fonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7W0Q5n-wU.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://smartplayer.scaleup.com.br/fonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7W0Q5n-wU.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://smartplayer.scaleup.com.br/fonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7W0Q5n-wU.woff2) format('woff2');
    unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://smartplayer.scaleup.com.br/fonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7W0Q5n-wU.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://smartplayer.scaleup.com.br/fonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7W0Q5n-wU.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://smartplayer.scaleup.com.br/fonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  :root {
    --font-family: 'Inter';
    --font-style: normal;

    --font-weight--regular: 400;
    --font-weight--medium: 500;
    --font-weight--semibold: 600;
    --font-weight--bold: 700;

    --color-default--0: #FFFFFF;
    --color-default--25: #FCFCFD;
    --color-default--50: #F9FAFB;
    --color-default--100: #F2F4F7;
    --color-default--200: #EAECF0;
    --color-default--300: #D0D5DD;
    --color-default--400: #98A2B3;
    --color-default--500: #667085;
    --color-default--600: #475467;
    --color-default--700: #344054;
    --color-default--800: #182230;
    --color-default--900: #101828;
    --color-default--950: #0C111D;
    --color-default--1000: #05080f;

    --color-brand--25: #F5F8FF;
    --color-brand--50: #EEF4FF;
    --color-brand--100: #E0EAFF;
    --color-brand--200: #C7D7FE;
    --color-brand--300: #A4BCFD;
    --color-brand--400: #8098F9;
    --color-brand--500: #6172F3;
    --color-brand--600: #444CE7;
    --color-brand--700: #3538CD;
    --color-brand--800: #2D31A6;
    --color-brand--900: #2D3282;
    --color-brand--950: #1F235B;

    --color-success--25: #F6FEF9;
    --color-success--50: #ECFDF3;
    --color-success--100: #DCFAE6;
    --color-success--200: #ABEFC6;
    --color-success--300: #75E0A7;
    --color-success--400: #47CD89;
    --color-success--500: #17B26A;
    --color-success--600: #079455;
    --color-success--700: #067647;
    --color-success--800: #085D3A;
    --color-success--900: #074D31;
    --color-success--950: #053321;

    --color-warning--25: #FFFCF5;
    --color-warning--50: #FFFAEB;
    --color-warning--100: #FEF0C7;
    --color-warning--200: #FEDF89;
    --color-warning--300: #FEC84B;
    --color-warning--400: #FDB022;
    --color-warning--500: #F79009;
    --color-warning--600: #DC6803;
    --color-warning--700: #B54708;
    --color-warning--800: #93370D;
    --color-warning--900: #7A2E0E;
    --color-warning--950: #4E1D09;

    --color-error--25: #FFFBFA;
    --color-error--50: #FEF3F2;
    --color-error--100: #FEE4E2;
    --color-error--200: #FECDCA;
    --color-error--300: #FDA29B;
    --color-error--400: #F97066;
    --color-error--500: #F04438;
    --color-error--600: #D92D20;
    --color-error--700: #B42318;
    --color-error--800: #912018;
    --color-error--900: #7A271A;
    --color-error--950: #55160C;

    --color-violet--50: #F5F3FF;
    --color-violet--400: #A48AFB;
    --color-violet--500: #875BF7;
    --color-violet--600: #7839EE;
    --color-violet--950: #2E125E;

    --color-cyan--50: #ECFDFF;
    --color-cyan--400: #22CCEE;
    --color-cyan--500: #06AED4;
    --color-cyan--600: #088AB2;
    --color-cyan--950: #0D2D3A;

    --color-pink--50: #FDF2FA;
    --color-pink--400: #F670C7;
    --color-pink--500: #EE46BC;
    --color-pink--600: #DD2590;
    --color-pink--950: #4E0D30;

    --color-yellow--50: #FEFBE8;
    --color-yellow--400: #FAC515;
    --color-yellow--500: #EAAA08;
    --color-yellow--600: #CA8504;
    --color-yellow--950: #542C0D;

    --background-color: var(--color-default--0);
    --background-color---embed: var(--color-default--0);

    /*** New Typography ***/
    --letter-spacing__display--md: -0.72px;
    --letter-spacing__display--lg: -0.96px;
    --letter-spacing__display--xl: -1.2px;
    --letter-spacing__display--2xl: -1.44px;

    --line-height__display--xxs: 30px;
    --line-height__display--xs: 32px;
    --line-height__display--sm: 38px;
    --line-height__display--md: 44px;
    --line-height__display--lg: 60px;
    --line-height__display--xl: 72px;
    --line-height__display--2xl: 90px;

    --font-size__display--xxs: 20px;
    --font-size__display--xs: 24px;
    --font-size__display--sm: 30px;
    --font-size__display--md: 36px;
    --font-size__display--lg: 48px;
    --font-size__display--xl: 60px;
    --font-size__display--2xl: 72px;

    --line-height__text--xxs: 14px;
    --line-height__text--xs: 18px;
    --line-height__text--sm: 20px;
    --line-height__text--md: 24px;
    --line-height__text--lg: 28px;
    --line-height__text--xl: 30px;

    --font-size__text--xxs: 8px;
    --font-size__text--xs: 12px;
    --font-size__text--sm: 14px;
    --font-size__text--md: 16px;
    --font-size__text--lg: 18px;
    --font-size__text--xl: 20px;
  };

  html, body {
    background-color: var(--background-color);
    color: var(--color-default--950);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    text-size-adjust: 100%;
    font-size: var(--font-size__text--md);
    font-weight: var(--font-weight--medium);
    font-family: var(--font-family);
  }

  html, body, p, h1, h2, h3, h4, h5, h6 {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    margin: 0;
    padding: 0;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
  }

  .hubspotChat--hide {
    display: none !important;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }
`;

export default GlobalStyle;
