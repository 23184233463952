const development: boolean = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

let environment = {
  production: false,
  mercadoPagoPublicKey: 'TEST-7d317a45-69cf-4fc0-add4-cc91674d0b19',
  authenticationServer: 'https://services.scaleup.com.br/authentication',
  transactionalServer: 'https://services.scaleup.com.br/transacional',
  appServer: 'https://services.scaleup.com.br/app',
  reports: 'https://services.scaleup.com.br/reports',
  backoffice: 'https://services.scaleup.com.br/backoffice',
  events: 'https://services.scaleup.com.br/events',
  googleAnalytics: 'G-ZPCD3SBQLD',
};


if (!development) {
  environment = {
    production: true,
    mercadoPagoPublicKey: 'APP_USR-e232e4ec-817d-4e7f-a248-d521d2450383',
    authenticationServer: 'https://services.scaleup.com.br/authentication',
    transactionalServer: 'https://services.scaleup.com.br/transacional',
    appServer: 'https://services.scaleup.com.br/app',
    reports: 'https://services.scaleup.com.br/reports',
    backoffice: 'https://services.scaleup.com.br/backoffice',
    events: 'https://services.scaleup.com.br/events',
    googleAnalytics: 'G-ZPCD3SBQLD',
  }
}

export default environment;
