import {useEffect, useState} from 'react';

export interface UserThemeUseCase {
  userTheme: 'light' | 'dark';
  toggleTheme: () => void;
}

const useTheme = (): UserThemeUseCase => {
  const [userTheme, setUserTheme] = useState<'light' | 'dark'>('light');

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setUserTheme(storedTheme as 'light' | 'dark');
    } else {
      const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
      setUserTheme(prefersDarkMode ? 'dark' : 'light');
    }
  }, []);

  const toggleTheme = () => {
    const newTheme = userTheme === 'light' ? 'dark' : 'light';
    setUserTheme(newTheme);
    localStorage.setItem('theme', newTheme);
  };

  return {userTheme, toggleTheme};
};

export default useTheme;
