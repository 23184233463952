export default {
  //Gray
  default: {
    0: 'var(--color-default--1000)',
    25: 'var(--color-default--950)',
    50: 'var(--color-default--950)',
    100: 'var(--color-default--900)',
    200: 'var(--color-default--800)',
    300: 'var(--color-default--700)',
    400: 'var(--color-default--500)',
    500: 'var(--color-default--500)',
    600: 'var(--color-default--400)',
    700: 'var(--color-default--300)',
    800: 'var(--color-default--200)',
    900: 'var(--color-default--50)',
    950: 'var(--color-default--50)',
    1000: 'var(--color-default--0)',
  },
  //Brand
  brand: {
    25: 'var(--color-brand--950)',
    50: 'var(--color-default--950)',
    100: 'var(--color-brand--600)',
    200: 'var(--color-brand--700)',
    300: 'var(--color-brand--400)',
    400: 'var(--color-brand--500)',
    500: 'var(--color-brand--500)',
    600: 'var(--color-brand--600)',
    700: 'var(--color-brand--400)',
    800: 'var(--color-brand--100)',
    900: 'var(--color-brand--50)',
    950: 'var(--color-brand--25)',
  },
  //Success
  success: {
    25: 'var(--color-success--950)',
    50: 'var(--color-success--950)',
    100: 'var(--color-success--900)',
    200: 'var(--color-success--800)',
    300: 'var(--color-success--700)',
    400: 'var(--color-success--600)',
    500: 'var(--color-success--500)',
    600: 'var(--color-success--400)',
    700: 'var(--color-success--300)',
    800: 'var(--color-success--200)',
    900: 'var(--color-success--100)',
    950: 'var(--color-success--50)',
  },
  //Warning
  warning: {
    25: 'var(--color-warning--950)',
    50: 'var(--color-warning--950)',
    100: 'var(--color-warning--900)',
    200: 'var(--color-warning--800)',
    300: 'var(--color-warning--700)',
    400: 'var(--color-warning--600)',
    500: 'var(--color-warning--500)',
    600: 'var(--color-warning--400)',
    700: 'var(--color-warning--300)',
    800: 'var(--color-warning--200)',
    900: 'var(--color-warning--100)',
    950: 'var(--color-warning--50)',
  },
  //Error
  error: {
    25: 'var(--color-error--950)',
    50: 'var(--color-error--950)',
    100: 'var(--color-error--900)',
    200: 'var(--color-error--800)',
    300: 'var(--color-error--700)',
    400: 'var(--color-error--600)',
    500: 'var(--color-error--500)',
    600: 'var(--color-error--400)',
    700: 'var(--color-error--300)',
    800: 'var(--color-error--200)',
    900: 'var(--color-error--100)',
    950: 'var(--color-error--50)',
  },
  //Violet
  violet: {
    50: 'var(--color-violet--950)',
    400: 'var(--color-violet--400)',
    500: 'var(--color-violet--500)',
    600: 'var(--color-violet--600)',
    950: 'var(--color-violet--50)',
  },
  //Cyan
  cyan: {
    50: 'var(--color-cyan--950)',
    400: 'var(--color-cyan--400)',
    500: 'var(--color-cyan--500)',
    600: 'var(--color-cyan--600)',
    950: 'var(--color-cyan--50)',
  },
  //Pink
  pink: {
    50: 'var(--color-pink--950)',
    400: 'var(--color-pink--400)',
    500: 'var(--color-pink--500)',
    600: 'var(--color-pink--600)',
    950: 'var(--color-pink--50)',
  },
  //Yellow
  yellow: {
    50: 'var(--color-yellow--950)',
    400: 'var(--color-yellow--400)',
    500: 'var(--color-yellow--500)',
    600: 'var(--color-yellow--600)',
    950: 'var(--color-yellow--50)',
  },
};
