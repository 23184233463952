import {HttpClient, HttpStatusCode} from '@Data/Protocols/Http';
import environment from '@Infra/Config';
import {
  Auth,
  AuthResponse,
  Medias,
  MediasResponse,
  UploadFile,
  UploadFileResponse,
  Folder,
  FolderResponse,
  Search,
  Files,
  FileDetail,
  FileMetadata,
  FileMetadataResponse,
  DownloadFile,
  FileResponse,
  FilesResponse,
  AnalyticsResponse,
  ConfigResponse,
  Config,
  WaterMark,
  FoldersDelete,
  MediasDelete,
  DeleteResponse,
  Quota,
  UpdateFileResponse,
  UpdateFile,
  WebhookList,
  WebhookListResponse,
  CreatorKeysResponse,
  GetCreatorKeysResponse,
  FileAI,
  FileAIResponseMindMap,
  FileAIResponseQuestion,
  FileAIResponseQuestions,
  FileAIResponseSearch,
  FileAIResponseSummary,
  AIProducts,
  FileAIResponse,
  FileAIStatus,
  FileAIResponseShorts,
  FileAIResponseSubtitles,
  FileAIResponseSummaryVideo,
  FileMetadataStatus,
  PublishAiProduct,
  PublishAiProductResponse,
  FileRename,
  PostConfig,
  DownloadSubtitle,
  DownloadSubtitleResponse,
  SubtitleRequestLanguage,
  SubtitleRequestLanguageResponse,
  ReprocessAIProducts,
  ReprocessAIProductsResponse,
  UpdateAIProduct,
  UpdateAIProductResponse,
  DownloadShorts,
  CreateAIList,
  BatchResponse,
  DownloadCSV,
  DownloadCSVResponse,
  PosterDelete,
  GetPublicResponse,
  GetPublic,
  PublicPublish,
  MoveFolder,
  MoveFolderResponse,
  AiProductsInfo,
  AiProduct,
  UpdateQuizAIProduct,
  UpdateQuizAIProductResponse,
  UserShortsConfigs,
  FileAIResponseSummaryText,
  UploadYoutubeFile,
  FileAIResponseSentence,
  MemberAreaResponse,
  UserConfig,
  UserConfigResponse,
  FileAIResponseToDoList,
  UpdateToDoAIProduct,
  UpdateToDoAIProductResponse,
  PersonalizedSubtitleResponse,
  PersonalizedSubtitle,
  UploadLinkFile,
} from '@src/Data/Model/FileSystem';

export default class DataSourceFileSystem {
  constructor(private readonly httpClient: HttpClient<any>) {}

  public authenticate = async (parameters: Auth): Promise<AuthResponse> => {
    // return new Promise<AuthResponse>(async (resolve) => {
    //   resolve({
    //     success: parameters.token === '123456',
    //     message: 'Problema para autenticar',
    //   });
    // });

    const url = `${environment.authenticationServer}/v1/oauth/token/users/temporary?grant_type=refresh_token&refresh_token=${parameters.token}`;

    return this.httpClient
      .post({
        url,
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
        withCredentials: true,
      })
      .then((response): AuthResponse => {
        const {headers, body, statusCode} = response;

        if (statusCode === HttpStatusCode.ok) {
          return {
            success: true,
            message: '',
            token: headers && headers.authorization ? headers.authorization : '',
            aiProductTypes: body?.aiProductTypes,
            limitedResource: body?.limitedResource,
            name: body?.user.name,
          };
        }

        return {
          success: false,
          message: body?.errors ? body?.errors[0]?.message : 'Não foi possível realizar a autenticação!',
          token: '',
          aiProductTypes: [],
          limitedResource: true,
        };
      });
  };

  public GetUserInfo = (): Promise<{success: boolean}> => {
    const url = `${environment.authenticationServer}/v1/oauth/token`;

    return this.httpClient
      .get({
        url,
      })
      .then((response): {success: boolean} => {
        if (response.statusCode === HttpStatusCode.ok) {
          return {
            success: true,
          };
        }

        return {success: false};
      });
  };

  public getQuotaValue = async (): Promise<Quota | null> => {
    const url = `${environment.backoffice}/v1/quotas/over-quotas`;

    return this.httpClient
      .get({
        url,
        headers: {
          'content-type': 'application/json',
        },
      })
      .then((response): Quota | null => {
        const {body, statusCode} = response;

        if (statusCode === HttpStatusCode.ok) {
          return {
            isOverQuota: body?.isOverQuota != undefined ? body?.isOverQuota : null,
          };
        }

        return null;
      });
  };

  public searchFiles = async (parameters: Search): Promise<FilesResponse> => {
    const url = `${environment.backoffice}/v2/folders/medias?page=${parameters.page || 0}&size=${
      parameters.pageSize || 0
    }&name=${parameters.search || ''}`;

    return this.httpClient
      .get({
        url,
        headers: {
          'content-type': 'application/json',
        },
      })
      .then((response): FilesResponse => {
        const {body, statusCode} = response;

        if (statusCode === HttpStatusCode.ok) {
          return {
            content: body.content.map((item: any) => {
              if (!!item['folder']) {
                return {
                  type: 'folder',
                  code: item.folder?.code,
                  name: item.folder?.name,
                  path: item?.path,
                  status: '',
                  items: item.totalItems,
                  totalDurationValue: item?.totalDurationValue || item.totalDuration,
                  ai: false,
                };
              } else if (!!item['media']) {
                return {
                  type: 'media',
                  code: item?.media?.code,
                  name: item?.media?.name,
                  path: item?.path,
                  thumb: item?.media?.thumb,
                  status: (item?.media?.status || '').toString().toUpperCase(),
                  items: item.totalItems,
                  totalDurationValue: item?.totalDurationValue || item.totalDuration,
                  ai: item?.media?.aiEnabled,
                };
              }
            }),
            size: body?.size,
            number: body?.number,
            numberOfElements: body?.numberOfElements,
            totalElements: body?.totalElements,
            totalPages: body.totalPages,
          };
        }

        return {
          content: [],
        };
      });
  };

  public listFiles = async (parameters: Files): Promise<FilesResponse> => {
    const url = `${environment.backoffice}/v2/folders?page=${parameters.page}&root-folder-code=${
      parameters.folder || ''
    }&size=${parameters.pageSize}${parameters.resourceType ? `&resource-type=${parameters.resourceType}` : ''}${
      parameters.status ? `&status=${parameters.status}` : ''
    }`;

    return this.httpClient
      .get({
        url,
        headers: {
          'content-type': 'application/json',
        },
      })
      .then((response): FilesResponse => {
        const {body} = response;

        if (response.statusCode === HttpStatusCode.ok) {
          let breadcrumbs: FilesResponse['breadcrumbs'] = [];

          const paths = (body?.parent?.path || '').split('/').slice(0, -1);
          const ids = (body?.parent?.codeFoldersPath || '').split(',');

          ids.forEach((item: string, index: number) => {
            breadcrumbs?.push({
              code: item,
              name: paths[index],
            });
          });
          return {
            breadcrumbs,
            content: (body?.pageableItems?.content || []).map((item: any) => {
              if (!!item['folder']) {
                return {
                  type: 'folder',
                  code: item.folder?.code,
                  name: item.folder?.name,
                  size: item.totalSizeValue,
                  time: item.totalDuration,
                  items: item.totalItems,
                  totalDurationValue: item?.totalDurationValue || item.totalDuration,
                  totalSizeValue:
                    (item?.totalSizeValue || item.totalSize) === 0 ? '-' : item?.totalSizeValue || item.totalSize,
                  status: '',
                  resolution: '',
                  ai: false,
                  sizeMb: item?.totalSize,
                  updatedAt: item?.updatedAt,
                  updatedAtValue: item?.updatedAtValue,
                };
              } else if (!!item['media']) {
                return {
                  type: 'media',
                  code: item?.media?.code,
                  name: item?.media?.name,
                  thumb: item?.media?.thumb,
                  size: item.totalSizeValue,
                  time: item.totalDuration,
                  items: item.totalItems,
                  status: (item?.media?.status || '').toString().toUpperCase(),
                  totalDurationValue: item?.totalDurationValue || item.totalDuration,
                  totalSizeValue:
                    (item?.totalSizeValue || item.totalSize) === 0 ? '-' : item?.totalSizeValue || item.totalSize,
                  resolution: '',
                  ai: item?.media?.aiEnabled,
                  sizeMb: item?.totalSize,
                  updatedAt: item?.updatedAt,
                  updatedAtValue: item?.updatedAtValue,
                };
              }
            }),
            size: body?.pageableItems?.size,
            number: body?.pageableItems?.number,
            numberOfElements: body?.pageableItems?.numberOfElements,
            totalElements: body?.pageableItems?.totalElements,
            totalPages: body.pageableItems?.totalPages,
            aiPresetConfig: body?.aiPresetConfig,
            ...body,
          };
        }

        return {
          content: [],
        };
      });
  };

  private checkStatus = (type: string) => (item: {type: string; status: string}) =>
    item.type === type && item.status === 'COMPLETED';

  private checkViewed = (type: string) => (item: {type: string; viewed: boolean; status: string}) =>
    item.type === type && item.viewed && item.status === 'COMPLETED';

  private checkAiProducts = (type: string) => (item: AiProductsInfo) => item.type === type;

  public getFileDetail = async (parameters: FileDetail): Promise<FileResponse> => {
    const url = `${environment.backoffice}/v1/medias/${parameters.code || ''}/wrappers`;

    return this.httpClient
      .get({
        url,
        headers: {
          'content-type': 'application/json',
        },
      })
      .then((response): FileResponse => {
        const {body, statusCode} = response;

        if (statusCode === HttpStatusCode.ok) {
          let breadcrumbs: FilesResponse['breadcrumbs'] = [];

          const paths = (body?.parent?.path || '').split('/').slice(0, -1);
          const ids = (body?.parent?.codeFoldersPath || '').split(',');

          ids.forEach((item: string, index: number) => {
            breadcrumbs?.push({
              code: item,
              name: paths[index],
            });
          });

          const aiStatus = {
            mindMap: (body?.aiProducts || []).filter(this.checkStatus('MIND_MAP')).length > 0,
            questions: (body?.aiProducts || []).filter(this.checkStatus('QUESTION')).length > 0,
            search: (body?.aiProducts || []).filter(this.checkStatus('SMART_SEARCH')).length > 0,
            sentence: (body?.aiProducts || []).filter(this.checkStatus('SENTENCE')).length > 0,
            summary: (body?.aiProducts || []).filter(this.checkStatus('SUMMARY')).length > 0,
            summaryText: (body?.aiProducts || []).filter(this.checkStatus('SUMMARY_TEXT')).length > 0,
            summaryVideo: (body?.aiProducts || []).filter(this.checkStatus('SUMMARY_VIDEO')).length > 0,
            shorts: (body?.aiProducts || []).filter(this.checkStatus('SHORTS')).length > 0,
            subTitles: (body?.aiProducts || []).filter(this.checkStatus('SUBTITLES')).length > 0,
            toDoList: (body?.aiProducts || []).filter(this.checkStatus('TO_DO_LIST')).length > 0,
          };

          const aiViewed = {
            mindMap: (body?.aiProducts || []).filter(this.checkViewed('MIND_MAP')).length > 0,
            questions: (body?.aiProducts || []).filter(this.checkViewed('QUESTION')).length > 0,
            search: (body?.aiProducts || []).filter(this.checkViewed('SMART_SEARCH')).length > 0,
            sentence: (body?.aiProducts || []).filter(this.checkViewed('SENTENCE')).length > 0,
            summary: (body?.aiProducts || []).filter(this.checkViewed('SUMMARY')).length > 0,
            summaryText: (body?.aiProducts || []).filter(this.checkViewed('SUMMARY_TEXT')).length > 0,
            summaryVideo: (body?.aiProducts || []).filter(this.checkViewed('SUMMARY_VIDEO')).length > 0,
            shorts: (body?.aiProducts || []).filter(this.checkViewed('SHORTS')).length > 0,
            subTitles: (body?.aiProducts || []).filter(this.checkViewed('SUBTITLES')).length > 0,
            toDoList: (body?.aiProducts || []).filter(this.checkViewed('TO_DO_LIST')).length > 0,
          };

          const aiProductsInfo = {
            mindMap: (body?.aiProducts || []).filter(this.checkAiProducts('MIND_MAP'))[0],
            questions: (body?.aiProducts || []).filter(this.checkAiProducts('QUESTION'))[0],
            search: (body?.aiProducts || []).filter(this.checkAiProducts('SMART_SEARCH'))[0],
            sentence: (body?.aiProducts || []).filter(this.checkAiProducts('SENTENCE'))[0],
            summary: (body?.aiProducts || []).filter(this.checkAiProducts('SUMMARY'))[0],
            summaryText: (body?.aiProducts || []).filter(this.checkAiProducts('SUMMARY_TEXT'))[0],
            summaryVideo: (body?.aiProducts || []).filter(this.checkAiProducts('SUMMARY_VIDEO'))[0],
            shorts: (body?.aiProducts || []).filter(this.checkAiProducts('SHORTS'))[0],
            subTitles: (body?.aiProducts || []).filter(this.checkAiProducts('SUBTITLES'))[0],
            toDoList: (body?.aiProducts || []).filter(this.checkAiProducts('TO_DO_LIST'))[0],
          };

          return {
            breadcrumbs,
            type: 'media',
            code: body?.media?.code,
            name: body?.media?.name || '',
            description: body?.media?.description || '',
            poster: body?.media?.poster || '',
            totalSizeValue: body?.media?.sizeValue || body?.media?.size,
            totalDuration: body?.media?.duration || 0,
            totalDurationValue: body?.media?.durationValue || body?.media?.duration,
            createdAtValue: body?.media?.createdAtValue,
            resolution: body?.media?.resolution,
            aspectRatio: body?.media?.aspectRatio,
            status: (body?.media?.status || '').toString().toUpperCase(),
            publicMedia: body?.media?.publicMedia,
            ai: body?.media?.aiEnabled || false,
            hasAILoading: (body.aiProducts || []).filter((item: any) => item.status !== 'COMPLETED').length > 0,
            aiStatus,
            aiViewed,
            functionalityOptions: body?.media?.functionalityOptions,
            customizationOptions: body?.media?.customizationOptions,
            previousMediaCode: body?.previousMediaCode,
            nextMediaCode: body?.nextMediaCode,
            totalMediasInFolder: body?.totalMediasInFolder,
            position: body?.position,
            aiProducts: aiProductsInfo,
            aiProductTypes: body?.aiProductTypes,
          };
        }

        return {
          code: parameters.code,
          name: '',
        };
      });
  };

  public getAIStatus = async (parameters: FileDetail): Promise<FileAIStatus> => {
    const url = `${environment.backoffice}/v1/medias/${parameters.code || ''}/ai-products/status`;

    return this.httpClient
      .get({
        url,
        headers: {
          'content-type': 'application/json',
        },
      })
      .then((response): FileAIStatus => {
        const {body, statusCode} = response;

        if (statusCode === HttpStatusCode.ok) {
          return {
            aiStatus: {
              mindMap: (body || []).filter(this.checkStatus('MIND_MAP')).length > 0,
              questions: (body || []).filter(this.checkStatus('QUESTION')).length > 0,
              search: (body || []).filter(this.checkStatus('SMART_SEARCH')).length > 0,
              sentence: (body || []).filter(this.checkStatus('SENTENCE')).length > 0,
              summary: (body || []).filter(this.checkStatus('SUMMARY')).length > 0,
              summaryText: (body || []).filter(this.checkStatus('SUMMARY_TEXT')).length > 0,
              summaryVideo: (body || []).filter(this.checkStatus('SUMMARY_VIDEO')).length > 0,
              shorts: (body || []).filter(this.checkStatus('SHORTS')).length > 0,
              subTitles: (body || []).filter(this.checkStatus('SUBTITLES')).length > 0,
              toDoList: (body || []).filter(this.checkStatus('TO_DO_LIST')).length > 0,
            },
            aiProducts: (body || []).map((item: any) => ({status: item.status, type: item.type})),
            products: {
              mindMap: (body || []).filter(this.checkAiProducts('MIND_MAP'))[0],
              questions: (body || []).filter(this.checkAiProducts('QUESTION'))[0],
              search: (body || []).filter(this.checkAiProducts('SMART_SEARCH'))[0],
              sentence: (body || []).filter(this.checkAiProducts('SENTENCE'))[0],
              summary: (body || []).filter(this.checkAiProducts('SUMMARY'))[0],
              summaryText: (body || []).filter(this.checkAiProducts('SUMMARY_TEXT'))[0],
              summaryVideo: (body || []).filter(this.checkAiProducts('SUMMARY_VIDEO'))[0],
              shorts: (body || []).filter(this.checkAiProducts('SHORTS'))[0],
              subTitles: (body || []).filter(this.checkAiProducts('SUBTITLES'))[0],
              toDoList: (body || []).filter(this.checkAiProducts('TO_DO_LIST'))[0],
            },
          };
        }

        return {
          aiProducts: [],
        };
      });
  };

  public reprocessAIProducts = (parameters: ReprocessAIProducts): Promise<ReprocessAIProductsResponse> => {
    const url = `${environment.backoffice}/v1/ai-products/medias/${parameters.mediaCode}/regenerates${
      parameters.productType ? `?product-types=${parameters.productType}` : ''
    }`;

    return this.httpClient
      .post({
        url,
        headers: {
          'content-type': 'application/json',
        },
      })
      .then((response): ReprocessAIProductsResponse => {
        const {body, statusCode} = response;

        if (statusCode === HttpStatusCode.ok) {
          return {
            success: true,
            message: 'Seus produtos de IA estão sendo reprocessados!',
          };
        }

        return {
          success: false,
          message: body?.errors ? body?.errors[0]?.message : 'Erro inesperado',
        };
      });
  };

  public getFileAIProduct = async (
    parameters: FileAI,
  ): Promise<
    FileAIResponse &
      AiProduct &
      (
        | FileAIResponseMindMap
        | FileAIResponseQuestion
        | FileAIResponseQuestions
        | FileAIResponseSearch
        | FileAIResponseSentence
        | FileAIResponseSummary
        | FileAIResponseSummaryText
        | FileAIResponseShorts
        | FileAIResponseSummaryVideo
        | FileAIResponseSubtitles
        | FileAIResponseToDoList
      )
  > => {
    const url = `${environment.backoffice}/v1/medias/${parameters.code || ''}/ai-products?product-types=${
      parameters.product
    }&formatted-markdown=false`;

    return this.httpClient
      .get({
        url,
        headers: {
          'content-type': 'application/json',
        },
      })
      .then((response): any => {
        const {body, statusCode} = response;

        if (statusCode !== HttpStatusCode.ok) {
          return {
            success: false,
            message: body?.errors ? body?.errors[0]?.message : 'Não foi possível carregar os dados do produto de IA!',
            aiCode: '',
            status: '',
          };
        }

        let item = body?.length > 0 ? body[0] : [];

        if (statusCode === HttpStatusCode.ok && parameters.product === 'MIND_MAP') {
          return {
            success: true,
            text: item.mindmap,
            aiCode: item.code,
            status: item.status,
          };
        } else if (statusCode === HttpStatusCode.ok && parameters.product === 'SUMMARY') {
          return {
            success: true,
            text: item.summary,
            aiCode: item.code,
            status: item.status,
          };
        } else if (statusCode === HttpStatusCode.ok && parameters.product === 'SUMMARY_TEXT') {
          return {
            success: true,
            text: item.summary,
            aiCode: item.code,
            status: item.status,
          };
        } else if (statusCode === HttpStatusCode.ok && parameters.product === 'QUESTION') {
          return {
            success: true,
            quantity: item.questionQuantity,
            questions: item.questions,
            aiCode: item.code,
            status: item.status,
          };
        } else if (statusCode === HttpStatusCode.ok && parameters.product === 'SMART_SEARCH') {
          return {
            success: true,
            active: item.status === 'COMPLETED',
            aiCode: item.code,
            status: item.status,
          };
        } else if (statusCode === HttpStatusCode.ok && parameters.product === 'SHORTS') {
          return {
            success: true,
            aiCode: item.code,
            status: item.status,
            active: item.status === 'COMPLETED',
            shorts: item.shorts || [],
            gifs: item.gifs || [],
            generatedShorts: item.generatedShorts || [],
          };
        } else if (statusCode === HttpStatusCode.ok && parameters.product === 'SUMMARY_VIDEO') {
          return {
            success: true,
            status: item.status,
            active: item.status === 'COMPLETED',
            code: item?.summarizedMedia?.code,
            aiCode: item.code,
            published: item.published,
          };
        } else if (statusCode === HttpStatusCode.ok && parameters.product === 'SUBTITLES') {
          return {
            success: true,
            status: item.status,
            active: item.status === 'COMPLETED',
            aiCode: item.code,
            published: item.published,
            transcriptionSubtitle: item.transcriptionSubtitle,
            subtitleRequestLanguages: item.subtitleRequestLanguages,
          };
        } else if (statusCode === HttpStatusCode.ok && parameters.product === 'SENTENCE') {
          return {
            success: true,
            status: item.status,
            active: item.status === 'COMPLETED',
            aiCode: item.code,
            published: item.published,
            sentences: item.sentences || [],
          };
        } else if (statusCode === HttpStatusCode.ok && parameters.product === 'TO_DO_LIST') {
          return {
            success: true,
            status: item.status,
            active: item.status === 'COMPLETED',
            aiCode: item.code,
            published: item.published,
            toDoList: item.toDoList,
          };
        }
      });
  };

  public updatePublishAi = async (parameters: PublishAiProduct): Promise<PublishAiProductResponse> => {
    const url = `${environment.backoffice}/v1/medias/ai-products/${parameters.aiCode}/publish?publish=${parameters.published}`;

    return this.httpClient
      .put({
        url,
        headers: {
          'content-type': 'application/json',
        },
        body: {
          ...parameters,
        },
      })
      .then((response): PublishAiProductResponse => {
        const {body, statusCode} = response;

        if (statusCode === HttpStatusCode.ok) {
          const message = parameters.published
            ? 'O produto de AI foi ligado no Player de Vídeo!'
            : 'O produto de AI foi desligado no Player de Vídeo!';
          return {
            success: true,
            message,
          };
        }

        return {
          success: false,
          message: body?.errors
            ? body?.errors[0]?.message
            : `Ops! Ocorreu um erro ao ${parameters.published ? 'ligar' : 'desligar'} o seu produto de IA!`,
        };
      });
  };

  public updatePublicPublish = async (parameters: PublicPublish): Promise<PublishAiProductResponse> => {
    const url = `${environment.backoffice}/v1/medias/ai-products/summary-public/${parameters.aiCode}?summary-public=${parameters.isPublic}`;

    return this.httpClient
      .post({
        url,
        headers: {
          'content-type': 'application/json',
        },
      })
      .then((response): PublishAiProductResponse => {
        const {body, statusCode} = response;
        if (statusCode === HttpStatusCode.ok) {
          const message = parameters.isPublic
            ? 'Este e-book agora está público!'
            : 'Este e-book não está mais público!';
          return {
            success: true,
            message,
          };
        }

        return {
          success: false,
          message: body?.errors ? body?.errors[0]?.message : '',
        };
      });
  };

  public getPublicStatus = async (parameters: GetPublic): Promise<GetPublicResponse> => {
    const url = `${environment.backoffice}/v1/medias/ai-products/summary-public/${parameters.code}`;

    return this.httpClient
      .get({
        url,
        headers: {
          'content-type': 'application/json',
        },
      })
      .then((response): GetPublicResponse => {
        const {body, statusCode} = response;
        if (statusCode === HttpStatusCode.ok) {
          return {
            success: true,
            message: 'O e-book está público!',
          };
        }

        return {
          success: false,
          message: body?.errors ? body?.errors[0]?.message : '',
        };
      });
  };

  public AiProductViewed = async (parameters: FileAI): Promise<FileAIResponse> => {
    const url = `${environment.backoffice}/v1/medias/${parameters.code || ''}/ai-products/views?product-types=${
      parameters.product
    }`;

    return this.httpClient
      .post({
        url,
        headers: {
          'content-type': 'application/json',
        },
      })
      .then((response): any => {
        const {body, statusCode} = response;

        if (statusCode !== HttpStatusCode.ok) {
          return {
            success: false,
            message: body?.errors ? body?.errors[0]?.message : 'Problema ao atualizar o status do produto de AI.',
          };
        }

        return {
          success: true,
          message: '',
        };
      });
  };

  public downloadVideo = async (parameters: FileDetail): Promise<DownloadFile> => {
    const url = `${environment.backoffice}/v1/medias/codes/${parameters.code}/downloads`;

    return this.httpClient
      .get({
        url,
        headers: {
          'content-type': 'application/json',
        },
      })
      .then((response): DownloadFile => {
        const {body, statusCode} = response;

        if (statusCode === HttpStatusCode.ok) {
          return {
            success: true,
            message: 'Download iniciado com sucesso!',
            url: body?.urlDownloadVideo,
          };
        }

        return {
          success: false,
          message: body?.errors ? body?.errors[0]?.message : 'Erro inesperado',
          url: '',
        };
      });
  };

  public downloadSubtitle = async (parameters: DownloadSubtitle): Promise<DownloadSubtitleResponse> => {
    const url = `${environment.backoffice}/v1/medias/${parameters.code}/subtitles/${parameters.languageCode}/downloads`;

    return this.httpClient
      .get({
        url,
        headers: {
          'content-type': 'application/json',
        },
      })
      .then((response): DownloadSubtitleResponse => {
        const {body, statusCode} = response;

        if (statusCode === HttpStatusCode.ok) {
          return {
            success: true,
            message: 'Download iniciado com sucesso!',
            url: body,
          };
        }

        return {
          success: false,
          message: body?.errors ? body?.errors[0]?.message : 'Erro inesperado',
          url: null,
        };
      });
  };

  public downloadShorts = async (parameters: DownloadShorts): Promise<DownloadFile> => {
    const url = `${environment.backoffice}/v1/ai-products/medias/${parameters.code}/shorts/${parameters.shortNumber}/downloads`;

    return this.httpClient
      .get({
        url,
        headers: {
          'content-type': 'application/json',
        },
      })
      .then((response): DownloadFile => {
        const {body, statusCode} = response;
        if (statusCode === HttpStatusCode.ok) {
          return {
            success: true,
            message: 'Download iniciado com sucesso!',
            url: body?.urlDownloadShortsVideo,
          };
        }

        return {
          success: false,
          message: body?.errors ? body?.errors[0]?.message : 'Erro inesperado',
          url: '',
        };
      });
  };

  public downloadCSV = async (parameters: DownloadCSV): Promise<DownloadCSVResponse> => {
    const url = `${environment.backoffice}/v1/medias/csv`;

    return this.httpClient
      .post({
        url,
        headers: {
          'content-type': 'application/json',
        },
        body: {
          ...parameters,
        },
      })
      .then((response): DownloadCSVResponse => {
        const {body, statusCode} = response;

        if (statusCode === HttpStatusCode.ok) {
          return {
            success: true,
            message: 'Download iniciado com sucesso!',
            body,
          };
        }

        return {
          success: false,
          message: body?.errors ? body?.errors[0]?.message : 'Erro inesperado',
          body: '',
        };
      });
  };

  public updateVideo = async (parameters: FileMetadata): Promise<FileMetadataResponse> => {
    const url = `${environment.backoffice}/v1/medias/codes/${parameters.code}`;

    return this.httpClient
      .put({
        url,
        headers: {
          'content-type': 'application/json',
        },
        body: {
          ...parameters,
        },
      })
      .then((response): FileMetadataResponse => {
        const {body, statusCode} = response;

        if (statusCode === HttpStatusCode.ok) {
          return {
            success: true,
            message: 'O vídeo foi atualizado com sucesso!',
            name: body?.name,
            description: body?.description,
            publicMedia: body?.publicMedia,
            customizationOptions: {
              playerColor: body?.customizationOptions?.playerColor,
              subtitleFontColor: body?.customizationOptions?.subtitleFontColor,
              subtitleBackgroundColor: body?.customizationOptions?.subtitleBackgroundColor,
            },
            functionalityOptions: {
              pip: body?.functionalityOptions?.pip,
              controls: body?.functionalityOptions?.controls,
              loop: body?.functionalityOptions?.loop,
              preload: body?.functionalityOptions?.preload,
              originalAspectRatio: body?.functionalityOptions?.originalAspectRatio,
              muted: body?.functionalityOptions?.muted,
              autoplay: body?.functionalityOptions?.autoplay,
              responsive: body?.functionalityOptions?.responsive,
              fullScreen: body?.functionalityOptions?.fullScreen,
            },
          };
        }

        return {
          success: false,
          message: body?.errors ? body?.errors[0]?.message : '',
        };
      });
  };

  public updateMediaStatus = async (parameters: FileMetadataStatus): Promise<FileMetadataResponse> => {
    const url = `${environment.backoffice}/v1/medias/codes/${parameters.code}`;

    return this.httpClient
      .put({
        url,
        headers: {
          'content-type': 'application/json',
        },
        body: {
          ...parameters,
        },
      })
      .then((response): FileMetadataResponse => {
        const {body, statusCode} = response;

        if (statusCode === HttpStatusCode.ok) {
          return {
            success: true,
            message: 'O vídeo foi atualizado com sucesso!',
          };
        }

        return {
          success: false,
          message: body?.errors ? body?.errors[0]?.message : '',
        };
      });
  };

  public UpdateSubtitleLanguages = async (
    parameters: SubtitleRequestLanguage,
  ): Promise<SubtitleRequestLanguageResponse> => {
    const url = `${environment.backoffice}/v1/medias/ai-products/${parameters.aiCode}/subtitle-languages`;

    return this.httpClient
      .put({
        url,
        headers: {
          'content-type': 'application/json',
        },
        body: parameters.languages,
      })
      .then((response): SubtitleRequestLanguageResponse => {
        const {body, statusCode} = response;

        if (statusCode === HttpStatusCode.ok) {
          const message = parameters.languages[0].enabled
            ? 'A legenda foi ligada no Player de Vídeo!'
            : 'A legenda foi desligada no Player de Vídeo!';
          return {
            success: true,
            message,
          };
        }

        const message = parameters.languages[0].enabled
          ? 'Ocorreu um erro ao ligar a legenda no Player de Vídeo!'
          : 'Ocorreu um erro ao desligar a legenda no Player de Vídeo!';

        return {
          success: false,
          message: body?.errors ? body?.errors[0]?.message : message,
        };
      });
  };

  public uploadPersonalizedSubtitle = async (
    parameters: PersonalizedSubtitle,
  ): Promise<PersonalizedSubtitleResponse> => {
    const url = `${environment.backoffice}/v1/ai-products/medias/${parameters.code}/subtitles/${parameters.language}`;

    return this.httpClient
      .put({
        url,
        headers: {
          'content-type': 'multipart/form-data',
        },
        body: parameters.subtitle,
      })
      .then((response): PersonalizedSubtitleResponse => {
        const {body, statusCode} = response;
        if (statusCode === HttpStatusCode.noContent) {
          return {
            success: true,
            message: 'Legenda atualizada com sucesso!',
          };
        }

        return {
          success: false,
          message: body?.errors ? body?.errors[0]?.message : 'Erro inesperado',
        };
      });
  };

  public updateAIProduct = async (parameters: UpdateAIProduct): Promise<UpdateAIProductResponse> => {
    const url = `${environment.backoffice}/v1/medias/ai-products/${parameters.aiCode}/contents`;

    return this.httpClient
      .put({
        url,
        headers: {
          'content-type': 'application/json',
        },
        body: {
          ...parameters,
        },
      })
      .then((response): UpdateAIProductResponse => {
        const {body, statusCode} = response;

        if (statusCode === HttpStatusCode.ok) {
          return {
            success: true,
            message: 'Produto de IA atualizado com sucesso!',
          };
        }

        return {
          success: false,
          message: body?.errors ? body?.errors[0]?.message : 'Erro inesperado',
        };
      });
  };

  public updateQuizAIProduct = async (parameters: UpdateQuizAIProduct): Promise<UpdateQuizAIProductResponse> => {
    const url = `${environment.backoffice}/v1/medias/ai-products/${parameters.aiCode}/contents`;

    return this.httpClient
      .put({
        url,
        headers: {
          'content-type': 'application/json',
        },
        body: {
          ...parameters,
        },
      })
      .then((response): UpdateAIProductResponse => {
        const {body, statusCode} = response;

        if (statusCode === HttpStatusCode.ok) {
          return {
            success: true,
            message: 'Quiz atualizado com sucesso!',
          };
        }

        return {
          success: false,
          message: body?.errors ? body?.errors[0]?.message : 'Oops! Ocorreu um erro ao atualizar o Quiz',
        };
      });
  };

  public updateToDoAIProduct = async (parameters: UpdateToDoAIProduct): Promise<UpdateToDoAIProductResponse> => {
    const url = `${environment.backoffice}/v1/medias/ai-products/${parameters.aiCode}/contents`;

    return this.httpClient
      .put({
        url,
        headers: {
          'content-type': 'application/json',
        },
        body: {
          ...parameters,
        },
      })
      .then((response): UpdateToDoAIProductResponse => {
        const {body, statusCode} = response;

        if (statusCode === HttpStatusCode.ok) {
          return {
            success: true,
            message: 'Plano de ação atualizado com sucesso!',
          };
        }

        return {
          success: false,
          message: body?.errors ? body?.errors[0]?.message : 'Oops! Ocorreu um erro ao atualizar o plano de ação',
        };
      });
  };

  public createAI = async (parameters: FileDetail): Promise<FileAIResponse> => {
    const url = `${environment.backoffice}/v1/medias/${parameters.code}/ai-products${
      parameters.productType ? `?product-types=${parameters.productType}` : ''
    }`;

    return this.httpClient
      .post({
        url,
        headers: {
          'content-type': 'application/json',
        },
        body: parameters.code,
      })
      .then((response): FileAIResponse => {
        const {body, statusCode} = response;

        if (statusCode === HttpStatusCode.ok) {
          return {
            success: true,
            message: 'Solicitação realizada com sucesso.',
          };
        }

        return {
          success: false,
          message: body?.errors ? body?.errors[0]?.message : 'Ocorreu um erro ao solicitar AI para este vídeo!',
        };
      });
  };

  public createAiBatch = async (parameters: CreateAIList): Promise<BatchResponse> => {
    const url = `${environment.backoffice}/v1/ai-products/lists`;

    return this.httpClient
      .post({
        url,
        headers: {
          'content-type': 'application/json',
        },
        body: parameters.code,
      })
      .then((response): BatchResponse => {
        const {body, statusCode} = response;

        if (statusCode === HttpStatusCode.ok) {
          return {
            success: true,
            message: 'Solicitação realizada com sucesso.',
          };
        }

        return {
          success: false,
          message: body?.errors ? body?.errors[0]?.message : 'Ocorreu um erro ao solicitar AI!',
        };
      });
  };

  public MoveFolder = async (parameters: MoveFolder): Promise<MoveFolderResponse> => {
    const url = `${environment.backoffice}/v1/folders/moves`;

    const itens: boolean =
      (!!parameters.folderCodes.length && !!parameters.mediaCodes.length) ||
      parameters.folderCodes.length > 1 ||
      parameters.mediaCodes.length > 1;

    return this.httpClient
      .put({
        url,
        headers: {
          'content-type': 'application/json',
        },
        body: {
          ...parameters,
        },
      })
      .then((response): MoveFolderResponse => {
        const {body, statusCode} = response;

        if (statusCode === HttpStatusCode.ok) {
          return {
            success: true,
            message: `${itens ? 'Itens movidos' : 'Item movido'} com sucesso!`,
          };
        }

        return {
          success: false,
          message: body?.errors
            ? body?.errors[0]?.message
            : `Ocorreu um erro ao mover ${itens ? 'os itens' : 'o item'}!`,
        };
      });
  };

  public getAnalytics = async (): Promise<AnalyticsResponse> => {
    // return new Promise<AnalyticsResponse>(async (resolve) => {
    //   resolve({
    //     dataSize: '10gb',
    //     dataTransfer: '10gb',
    //     videoCount: '10',
    //   });
    // });

    const url = `${environment.backoffice}/v1/analytics`;

    return this.httpClient
      .get({
        url,
        headers: {
          'content-type': 'application/json',
        },
      })
      .then((response): AnalyticsResponse => {
        const {body, statusCode} = response;

        if (statusCode === HttpStatusCode.ok) {
          return {
            quantityConsumed: body?.quantityMediaConsumedValue,
            dataTransferConsumed: body?.bandwidthConsumedValue,
            storageConsumed: body?.storageConsumedValue,
            minutesConsumed: body?.minutesConsumedValue,
            quantityContracted: body?.quantityMediaContractedValue,
            dataTransferContracted: body?.bandwidthContractedValue,
            storageContracted: body?.storageContractedValue,
            minutesContracted: body?.minutesContractedValue,
            dataTransferPercentage: body?.bandwidthPercentage,
            storagePercentage: body?.storagePercentage,
            minutesPercentage: body?.minutesPercentage,
          };
        }

        return {
          quantityConsumed: '-',
          dataTransferConsumed: '-',
          storageConsumed: '-',
          minutesConsumed: '-',
          quantityContracted: '-',
          dataTransferContracted: '-',
          storageContracted: '-',
          minutesContracted: '-',
          dataTransferPercentage: 0,
          storagePercentage: 0,
          minutesPercentage: 0,
        };
      });
  };

  public createFolders = (parameters: Folder): Promise<FolderResponse> => {
    const url = `${environment.backoffice}/v1/folders?root-folder-code=${parameters.folder || ''}`;

    return this.httpClient
      .post({
        url,
        headers: {
          'content-type': 'application/json',
        },
        body: {
          name: parameters.name,
        },
      })
      .then((response): FolderResponse => {
        const {body} = response;

        if (response.statusCode === HttpStatusCode.created) {
          return {
            success: true,
            message: '',
          };
        }

        if (response.statusCode === HttpStatusCode.badRequest && body.errors[0].category === 'quota') {
          return {
            success: false,
            message: body?.errors ? body?.errors[0]?.message : '',
            category: 'quota',
          };
        }

        return {
          success: false,
          message: body?.errors ? body?.errors[0]?.message : '',
        };
      });
  };

  public renameFolder = (parameters: Folder): Promise<FolderResponse> => {
    const url = `${environment.backoffice}/v1/folders/codes/${parameters.folder}`;

    return this.httpClient
      .put({
        url,
        headers: {
          'content-type': 'application/json',
        },
        body: {
          name: parameters.name,
        },
      })
      .then((response): FolderResponse => {
        const {body} = response;

        if (response.statusCode === HttpStatusCode.ok) {
          return {
            success: true,
            message: 'A pasta foi renomeada com sucesso!',
          };
        }

        return {
          success: false,
          message: body?.errors ? body?.errors[0]?.message : 'Erro inesperado',
        };
      });
  };

  public createMedias = (parameters: Medias): Promise<MediasResponse> => {
    const url = `${environment.backoffice}/v1/medias?root-folder-code=${parameters.folder || ''}`;

    return this.httpClient
      .post({
        url,
        headers: {
          'content-type': 'application/json',
        },
        body: parameters.items.map(item => ({
          ...item,
          publicMedia: false,
        })),
        signal: parameters.signal,
      })
      .then((response): MediasResponse => {
        const {body} = response;

        if (response.statusCode === HttpStatusCode.created) {
          return {
            success: true,
            items: body.map((item: any) => ({
              sequence: item?.sequence,
              code: item?.code,
              name: item?.name || '',
              description: item?.description || '',
              status: item?.status || '',
              publicMedia: item?.publicMedia || false,
              urlUpload: item?.urlsUpload?.urlUploadVideo || '',
              automaticallyRequestAi: item?.automaticallyRequestAi || false,
            })),
          };
        }

        if (response.statusCode === HttpStatusCode.badRequest && body.errors[0].category === 'quota') {
          return {
            success: false,
            message: response?.body?.errors ? response?.body?.errors[0]?.message : '',
            category: 'quota',
            items: [],
          };
        }

        return {
          success: false,
          message: response?.body?.errors ? response?.body?.errors[0]?.message : '',
          items: [],
        };
      });
  };

  public deleteFolder = (parameters: FoldersDelete): Promise<DeleteResponse> => {
    const url = `${environment.backoffice}/v1/folders/lists`;

    return this.httpClient
      .delete({
        url,
        headers: {
          'content-type': 'application/json',
        },
        body: parameters.items,
      })
      .then((response): DeleteResponse => {
        const {body} = response;

        if (response.statusCode === HttpStatusCode.ok || response.statusCode === HttpStatusCode.noContent) {
          return {
            success: true,
            message: 'A pasta foi removida com sucesso!',
            errorKey: '',
            ...body,
          };
        }

        return {
          success: false,
          message: body?.errors ? body?.errors[0]?.message : 'Ocorreu um erro ao tentar excluir!',
          errorKey: body?.errors ? body?.errors[0]?.errorKey : 'generic.message.key',
        };
      });
  };

  public deleteMedia = (parameters: MediasDelete): Promise<DeleteResponse> => {
    const url = `${environment.backoffice}/v1/medias/lists`;

    return this.httpClient
      .delete({
        url,
        headers: {
          'content-type': 'application/json',
        },
        body: parameters.items,
      })
      .then((response): DeleteResponse => {
        const {body} = response;

        if (response.statusCode === HttpStatusCode.ok || response.statusCode === HttpStatusCode.noContent) {
          return {
            success: true,
            message: 'A mídia foi removida com sucesso!',
            errorKey: '',
            ...body,
          };
        }

        return {
          success: false,
          message: body?.errors ? body?.errors[0]?.message : 'Ocorreu um erro ao tentar excluir!',
          errorKey: body?.errors ? body?.errors[0]?.errorKey : 'generic.message.key',
        };
      });
  };

  public renameMedia = async (parameters: FileRename): Promise<FileMetadataResponse> => {
    const url = `${environment.backoffice}/v1/medias/codes/${parameters.code}`;

    return this.httpClient
      .put({
        url,
        headers: {
          'content-type': 'application/json',
        },
        body: {
          ...parameters,
        },
      })
      .then((response): FileMetadataResponse => {
        const {body, statusCode} = response;

        if (statusCode === HttpStatusCode.ok) {
          return {
            success: true,
            message: 'O vídeo foi atualizado com sucesso!',
            name: body?.name || '',
            description: body?.description || '',
            publicMedia: body?.publicMedia || false,
          };
        }

        return {
          success: false,
          message: body?.errors ? body?.errors[0]?.message : '',
        };
      });
  };

  public updateMedia = (parameters: UpdateFile): Promise<UpdateFileResponse> => {
    const url = `${environment.backoffice}/v1/medias/links/${parameters.code}/files?video=${
      parameters.uploadType === 'media'
    }&poster=${parameters.uploadType === 'poster'}`;

    return this.httpClient
      .put({
        url,
        headers: {
          'content-type': 'application/json',
        },
      })
      .then((response): UpdateFileResponse => {
        const {body} = response;

        if (response.statusCode === HttpStatusCode.ok) {
          return {
            success: true,
            urlUpload: parameters.uploadType === 'media' ? body?.urlUploadVideo : body?.urlUploadPoster,
          };
        }

        return {
          success: false,
          message: body?.errors ? body?.errors[0]?.message : 'Erro inesperado',
        };
      })
      .catch(error => {
        console.log('error', error);

        return {
          success: false,
          message: error?.body?.errors[0]?.message,
        };
      });
  };

  public uploadMedias = (parameters: UploadFile): Promise<UploadFileResponse> => {
    const url = parameters.urlUpload;

    return this.httpClient
      .put({
        url,
        headers: {
          'Content-Type': parameters.type,
        },
        body: parameters.video,
        onUploadProgress: parameters.onUploadProgress,
        timeout: 59 * 60 * 1000,
        withCredentials: url.indexOf('.scaleup.') > -1,
        signal: parameters.signal,
      })
      .then((response): UploadFileResponse => {
        const {body} = response;

        if (response.statusCode === HttpStatusCode.ok) {
          return {
            success: true,
            code: parameters?.code,
          };
        }

        return {
          success: false,
          message: body?.errors ? body?.errors[0]?.message : 'Erro inesperado',
        };
      })
      .catch(error => {
        console.log('error', error);

        return {
          success: false,
          message: error?.body?.errors[0]?.message,
        };
      });
  };

  public uploadLinkMedia = (parameters: UploadLinkFile): Promise<UploadFileResponse> => {
    const url = `${environment.backoffice}/v1/medias?root-folder-code=${parameters.folderCode}`;

    return this.httpClient
      .post({
        url,
        headers: {
          'Content-Type': 'application/json',
        },
        body: [
          {
            urlDownload: parameters.urlDownload,
            automaticallyRequestAi: parameters.automaticallyRequestAi,
            publicMedia: parameters.publicMedia,
            name: parameters.name,
            downloadType: parameters.downloadType,
          },
        ],
        onUploadProgress: parameters.onUploadProgress,
        timeout: 59 * 60 * 1000,
        withCredentials: url.indexOf('.scaleup.') > -1,
      })
      .then((response): UploadFileResponse => {
        const {body} = response;

        if (response.statusCode === HttpStatusCode.created) {
          return {
            success: true,
            message: 'Arquivo enviado com sucesso!',
            code: parameters?.folderCode,
          };
        }

        return {
          success: false,
          message: body?.errors ? body?.errors[0]?.message : 'Erro inesperado',
        };
      })
      .catch(error => {
        console.log('error', error);

        return {
          success: false,
          message: error?.body?.errors[0]?.message,
        };
      });
  };

  public deletePoster = (parameters: PosterDelete): Promise<ConfigResponse> => {
    const url = `${environment.backoffice}/v1/medias/${parameters.code}/posters`;

    return this.httpClient
      .delete({
        url,
        headers: {
          'content-type': 'application/json',
        },
      })
      .then((response): ConfigResponse => {
        const {body} = response;

        if (
          response.statusCode === HttpStatusCode.created ||
          response.statusCode === HttpStatusCode.ok ||
          response.statusCode === HttpStatusCode.noContent
        ) {
          return {
            success: true,
            message: 'Sua Thumbnail foi removida com sucesso!',
            ...body,
          };
        }

        return {
          success: false,
          message: body?.errors ? body?.errors[0]?.message : 'Ocorreu um erro ao remover a sua Thumbnail!',
        };
      });
  };

  public getConfig = (): Promise<Config> => {
    const url = `${environment.backoffice}/v2/stream-configs`;

    return this.httpClient
      .get({
        url,
        headers: {
          'content-type': 'application/json',
        },
      })
      .then((response): Config => {
        const {statusCode, body} = response;

        if (statusCode === HttpStatusCode.ok) {
          return {
            ...body,
            allowedDomains: body?.allowedDomains || [],
            blockedDomains: body?.blockedDomains || [],
            customizationOptions: body?.customizationOptions,
            functionalityOptions: body?.functionalityOptions,
            resolutions: body?.resolutions,

            waterMark: body?.waterMark,
            aiProductTypes: body.aiProductTypes,
          };
        }

        return {
          allowedDomains: [],
          blockedDomains: [],

          customizationOptions: {
            playerColor: '#849DFF',
            subtitleFontColor: '#000000',
            subtitleBackgroundColor: '#000000',
          },
          functionalityOptions: {
            autoplay: false,
            controls: true,
            loop: false,
            responsive: true,
            fullScreen: false,
            muted: false,
            preload: true,
            pip: true,
            originalAspectRatio: false,
          },
          resolutions: {
            '240p': true,
            '360p': true,
            '480p': true,
            '720p': true,
            '1080p': true,
            '1440p': false,
            '2160p': false,
            '4320p': false,
          },
          aiProductTypes: [],
          waterMark: '',
        };
      });
  };

  public postConfig = (parameters: PostConfig): Promise<ConfigResponse> => {
    const url = `${environment.backoffice}/v2/stream-configs`;

    return this.httpClient
      .post({
        url,
        headers: {
          'content-type': 'application/json',
        },
        body: {
          ...parameters,
        },
      })
      .then((response): ConfigResponse => {
        const {body} = response;

        if (response.statusCode === HttpStatusCode.created) {
          return {
            success: true,
            message: 'Suas atualizações foram salvas com sucesso!',
            ...body,
          };
        }

        return {
          success: false,
          message: body?.errors ? body?.errors[0]?.message : 'Erro inesperado',
        };
      });
  };

  public getUserConfig = (): Promise<UserConfig> => {
    const url = `${environment.backoffice}/v1/user-configs/types?types=PLAYER`;

    return this.httpClient
      .get({
        url,
        headers: {
          'content-type': 'application/json',
        },
      })
      .then((response): UserConfig => {
        const {statusCode, body} = response;

        if (statusCode === HttpStatusCode.ok && body.length > 0) {
          return {
            playerColor: body[0].options.playerColor,
            fullScreen: body[0].options.fullScreen,
            pip: body[0].options.pip,
            autoplay: body[0].options.autoplay,
            responsive: body[0].options.responsive,
            preload: body[0].options.preload,
            loop: body[0].options.loop,
            originalAspectRatio: body[0].options.originalAspectRatio,
            controls: body[0].options.controls,
            muted: body[0].options.muted,
            logo: body[0].options.logo,
            bigPlay: body[0].options.bigPlay,
            chromecast: body[0].options.chromecast,
            airplay: body[0].options.airplay,
            showConfigurations: body[0].options.showConfigurations,
            keepWatching: body[0].options.keepWatching,
            progressBar: body[0].options.progressBar,
            speedControl: body[0].options.speedControl,
            qualityControl: body[0].options.qualityControl,
            volume: body[0].options.volume,
          };
        }

        return {};
      });
  };

  public postUserConfig = (parameters: UserConfig): Promise<UserConfigResponse> => {
    const url = `${environment.backoffice}/v1/user-configs/players`;

    return this.httpClient
      .post({
        url,
        headers: {
          'content-type': 'application/json',
        },
        body: {
          options: {
            ...parameters,
          },
        },
      })
      .then((response): UserConfigResponse => {
        const {body} = response;

        if (response.statusCode === HttpStatusCode.created) {
          return {
            success: true,
            message: 'Suas atualizações foram salvas com sucesso!',
            ...body,
          };
        }

        return {
          success: false,
          message: body?.errors ? body?.errors[0]?.message : 'Tente novamente mais tarde',
        };
      });
  };

  public getShortsConfig = (): Promise<UserShortsConfigs> => {
    const url = `${environment.backoffice}/v1/user-configs/types?types=SHORTS`;

    return this.httpClient
      .get({
        url,
        headers: {
          'content-type': 'application/json',
        },
      })
      .then((response): UserShortsConfigs => {
        const {statusCode, body} = response;

        if (statusCode === HttpStatusCode.ok) {
          return {
            shortTargetTime: body[0].options.shortTargetTime,
            shortSubtitleFontColor: body[0].options.shortSubtitleFontColor,
            shortSubtitleFontSize: body[0].options.shortSubtitleFontSize,
            shortSubtitleShadowColor: body[0].options.shortSubtitleShadowColor,
            shortSubtitleBackgroundColor: body[0].options.shortSubtitleBackgroundColor,
            shortSubtitleFontFamily: body[0].options.shortSubtitleFontFamily,
            headline: body[0].options.headline,
            shortSubtitleFontWeight: body[0].options.shortSubtitleFontWeight,
          };
        }

        return {};
      });
  };

  public postShortsConfig = (parameters: UserShortsConfigs): Promise<ConfigResponse> => {
    const url = `${environment.backoffice}/v1/user-configs/shorts`;

    return this.httpClient
      .post({
        url,
        headers: {
          'content-type': 'application/json',
        },
        body: {
          options: {...parameters},
        },
      })
      .then((response): ConfigResponse => {
        const {body} = response;

        if (response.statusCode === HttpStatusCode.created) {
          return {
            success: true,
            message: 'Suas atualizações foram salvas com sucesso!',
            ...body,
          };
        }

        return {
          success: false,
          message: body?.errors ? body?.errors[0]?.message : 'Oops, ocorreu um erro ao salvar suas atualizações',
        };
      });
  };

  public postConfigDomains = (parameters: Config): Promise<ConfigResponse> => {
    const url = `${environment.backoffice}/v1/stream-configs/domains`;

    return this.httpClient
      .post({
        url,
        headers: {
          'content-type': 'application/json',
        },
        body: {
          ...parameters,
        },
      })
      .then((response): ConfigResponse => {
        const {body} = response;

        if (response.statusCode === HttpStatusCode.created) {
          return {
            success: true,
            message: '',
            ...body,
          };
        }

        return {
          success: false,
          message: body?.errors ? body?.errors[0]?.message : 'Erro inesperado',
        };
      });
  };

  public postWaterMark = (parameters: WaterMark): Promise<ConfigResponse> => {
    const url = `${environment.backoffice}/v1/stream-configs/watermarks`;

    return this.httpClient
      .post({
        url,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        body: parameters.file,
      })
      .then((response): ConfigResponse => {
        const {body} = response;

        if (response.statusCode === HttpStatusCode.created) {
          return {
            success: true,
            message: 'Sua Thumbnail foi atualizada com sucesso!',
          };
        }

        return {
          success: false,
          message: body?.errors ? body?.errors[0]?.message : 'Erro inesperado',
        };
      })
      .catch(error => {
        console.log('error', error);

        return {
          success: false,
          message: error?.body?.errors[0]?.message,
        };
      });
  };

  public deleteWaterMark = (): Promise<ConfigResponse> => {
    const url = `${environment.backoffice}/v1/stream-configs/watermarks`;

    return this.httpClient
      .delete({
        url,
        headers: {
          'content-type': 'application/json',
        },
      })
      .then((response): ConfigResponse => {
        const {body} = response;

        if (response.statusCode === HttpStatusCode.created || response.statusCode === HttpStatusCode.ok) {
          return {
            success: true,
            message: 'Sua Thumbnail foi removida com sucesso!',
            ...body,
          };
        }

        return {
          success: false,
          message: body?.errors ? body?.errors[0]?.message : 'Erro inesperado',
        };
      });
  };

  public getWebhooks = (): Promise<WebhookListResponse> => {
    const url = `${environment.backoffice}/v1/partners-integrations-configs`;

    return this.httpClient
      .get({
        url,
        headers: {
          'content-type': 'application/json',
        },
      })
      .then((response): WebhookListResponse => {
        const {body, statusCode} = response;

        if (statusCode === HttpStatusCode.ok || statusCode === HttpStatusCode.noContent) {
          return {
            success: true,
            checkoutURL: body?.checkoutURL || '',
            statusMediasURLs: body?.statusMediasURLs || [],
            consumptionsURLs: body?.consumptionsURLs || [],
            ...body,
          };
        }

        return {
          success: false,
          checkoutURL: '',
          statusMediasURLs: [],
          consumptionsURLs: [],
          message: body?.errors ? body?.errors[0]?.message : 'Erro interno ao buscar webhooks!',
          ...body,
        };
      });
  };

  public postWebhook = (parameters: WebhookList): Promise<WebhookListResponse> => {
    const url = `${environment.backoffice}/v1/partners-integrations-configs`;

    return this.httpClient
      .post({
        url,
        headers: {
          'content-type': 'application/json',
        },
        body: {...parameters},
      })
      .then((response): WebhookListResponse => {
        const {body, statusCode} = response;

        if (statusCode === HttpStatusCode.created) {
          return {
            success: true,
            message: 'Webhook adicionado com sucesso',
          };
        }

        return {
          success: false,
          message: body?.errors ? body?.errors[0]?.message : 'Erro interno ao adicionar webhooks!',
        };
      })
      .catch(error => {
        console.log('error', error);

        return {
          success: false,
          message: error?.body?.errors[0]?.message,
        };
      });
  };

  public deleteWebhooks = (): Promise<WebhookListResponse> => {
    const url = `${environment.backoffice}/v1/webhook-configs`;

    return this.httpClient
      .delete({
        url,
        headers: {
          'content-type': 'application/json',
        },
      })
      .then((response): WebhookListResponse => {
        const {body, statusCode} = response;

        if (statusCode === HttpStatusCode.ok) {
          return {
            success: true,
            ...body,
          };
        }

        return {
          success: false,
          message: body?.errors ? body?.errors[0]?.message : 'Erro interno ao excluir webhooks!',
        };
      });
  };

  public createNewApiKey = (): Promise<CreatorKeysResponse> => {
    const url = `${environment.backoffice}/v1/creators/keys`;

    return this.httpClient
      .post({
        url,
        headers: {
          'content-type': 'application/json',
        },
      })
      .then((response): CreatorKeysResponse => {
        const {body, statusCode} = response;

        if (statusCode === HttpStatusCode.created) {
          return {
            success: true,
            message: 'Chave de API criada com sucesso',
            id: body?.id,
            name: body?.name,
            code: body?.code,
            clientId: body?.clientId,
            clientSecret: body?.clientSecret,
          };
        }

        return {
          success: false,
          message: body?.errors ? body?.errors[0]?.message : 'Erro interno ao criar chave de API',
        };
      });
  };

  public deleteApiKey = (): Promise<WebhookListResponse> => {
    const url = `${environment.backoffice}/v1/creators/keys`;

    return this.httpClient
      .delete({
        url,
        headers: {
          'content-type': 'application/json',
        },
      })
      .then((response): WebhookListResponse => {
        const {body, statusCode} = response;

        if (statusCode === HttpStatusCode.noContent) {
          return {
            success: true,
            message: 'Chave de API deletada com sucesso',
            ...body,
          };
        }

        return {
          success: false,
          message: body?.errors ? body?.errors[0]?.message : 'Erro interno ao excluir chave de API',
        };
      });
  };

  public getApiKey = (): Promise<GetCreatorKeysResponse> => {
    const url = `${environment.backoffice}/v1/creators/keys`;

    return this.httpClient
      .get({
        url,
        headers: {
          'content-type': 'application/json',
        },
      })
      .then((response): GetCreatorKeysResponse => {
        const {body, statusCode} = response;

        if (statusCode === HttpStatusCode.created) {
          return {
            hasKey: body.hasKey,
          };
        }

        return {
          hasKey: false,
        };
      });
  };

  public getUserMemberArea = (): Promise<MemberAreaResponse[]> => {
    const url = `${environment.backoffice}/v1/member-areas/users`;

    return this.httpClient
      .get({
        url,
      })
      .then((response): MemberAreaResponse[] => {
        const {body, statusCode} = response;

        if (statusCode === HttpStatusCode.ok) {
          return body as MemberAreaResponse[];
        }

        return [];
      });
  };
}
