import {useNavigate, useLocation} from 'react-router-dom';
import {HttpRequest, HttpMethod, HttpResponse, HttpMethods} from '@Data/Protocols/Http';
import {merge, BehaviorSubject} from 'rxjs';
// import { stream } from './ndjson';
import environment from '@Infra/Config';
import axios, {
  AxiosHeaders,
  AxiosResponse,
  AxiosError,
  AxiosRequestConfig,
  AxiosProxyConfig,
  AxiosPromise,
} from 'axios';
import {useUser} from '@src/Data/Provider/UserProvider';
import {UserUseCase} from '@src/Domain/UseCases/User';
import {useUserData} from '@src/Data/Provider/UserDataProvider';

const SKIP_LOGIN = 'X-Skip-Login';
const SKIP_INTERCEPTOR = 'X-Skip-Interceptor';
const SKIP_LOADER = 'X-Skip-Loader';
const SKIP_ERROR_MESSAGE = 'X-Skip-Error-Message';
const SKIP_RETRY = 'X-SkipRetry';

export default class HttpClient implements HttpClient {
  hasEmbed: boolean = false;
  axiosInstance = axios.create();
  internalToken: UserUseCase | null = null;

  requests: HttpRequest[] = [];
  tokenIsValid = new BehaviorSubject(true);
  tokenIsRefreshing = new BehaviorSubject(false);
  navigate = useNavigate();

  async onRequest(config: any): Promise<any> {
    if (!config) {
      config = {};
    }

    if (!config.headers) {
      config.headers = new AxiosHeaders();
    }

    if (
      config.url?.toString().indexOf(environment.authenticationServer) === -1 ||
      (!!config?.headers && config?.headers.has(SKIP_INTERCEPTOR))
    ) {
      return config;
    }

    return await new Promise((resolve, reject) => {
      this.tokenIsValid.subscribe(value => {
        if (!value) {
          return;
        }

        resolve(config);
      });
    });
  }

  onRequestError(error: AxiosError): Promise<AxiosError> {
    return Promise.reject(error);
  }

  onResponse(response: AxiosResponse): AxiosResponse {
    if (this.requests.length != 0) {
      this.requests.forEach((x: HttpRequest, i: number) => {
        if (response.config.url == x.url) {
          this.requests = this.requests.splice(i, 1);
        }
      });
    }

    return response;
  }

  async onResponseError(error: AxiosError): Promise<AxiosError | AxiosResponse> {
    if (!error || !error.isAxiosError) {
      return error;
    }

    if (
      error.config?.headers.has(SKIP_INTERCEPTOR) &&
      (error?.request?.responseURL).toString().indexOf('/v1/oauth/token/backoffice') > -1
    ) {
      return Promise.reject(error);
    }

    if (
      !error.config?.headers.has(SKIP_INTERCEPTOR) &&
      !error.config?.headers.has(SKIP_LOGIN) &&
      error?.response?.status !== 401
    ) {
      return Promise.reject(error);
    }

    if (error.config?.headers.has(SKIP_INTERCEPTOR) && error?.response?.status === 403) {
      return Promise.reject(error);
    }

    const {config} = error;

    if (!config) {
      return Promise.reject(error);
    }

    if (!this.tokenIsRefreshing.value) {
      this.tokenIsValid.next(false);
      await this.refreshToken();
    }

    return await new Promise(async (resolve, reject) => {
      this.tokenIsValid.subscribe(async isValid => {
        if (!isValid) {
          return;
        }

        const parameters = {
          method: config.method || 'get',
          url: config?.url || '',
          data: config?.data,
          headers: {
            ...config?.headers,
            ...(this.hasEmbed && config?.headers?.Authorization
              ? {
                  Authorization: `Bearer ${this.internalToken?.getToken() || ''}`,
                }
              : {}),
          },
          withCredentials: !this.hasEmbed,
        };

        const response = await this.axiosInstance.request({
          method: parameters.method,
          url: parameters.url,
          data: parameters.data || {},
          headers: parameters.headers,
          withCredentials: parameters.withCredentials,
        });

        return resolve(response);
      });
    });
  }

  async refreshToken(): Promise<any> {
    this.tokenIsRefreshing.next(true);
    // return new Promise((resolve, reject) => {
    // setTimeout(() => {
    // resolve(''
    return await this.post({
      url: `${environment.authenticationServer}/v1/oauth/token/backoffice`,
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
        [SKIP_INTERCEPTOR]: true,
      },
      body: 'grant_type=refresh_token',
      observe: 'response',
      withCredentials: this.hasEmbed ? true : undefined,
    })
      .then(result => {
        if (result.statusCode < 400) {
          this.internalToken?.updateToken(result?.headers?.authorization);

          this.tokenIsRefreshing.next(false);
          this.tokenIsValid.next(true);
        } else {
          this.tokenIsRefreshing.next(false);
          this.tokenIsValid.next(false);

          if (this.hasEmbed) {
            this.navigate('/embed/smart-player/sessao-encerrada');
          } else {
            this.navigate('/auth/login');
          }
        }
      })
      .catch(error => {
        console.log('error', error);

        if (this.hasEmbed) {
          this.navigate('/embed/smart-player/sessao-encerrada');
        } else {
          this.navigate('/auth/login');
        }
      });
    // );
    // }, 20000);
    // });
  }

  constructor() {
    const {pathname} = useLocation();
    this.internalToken = useUser();
    this.hasEmbed = pathname.includes('embed');
    this.axiosInstance.interceptors.request.use(this.onRequest.bind(this), this.onRequestError.bind(this));
    this.axiosInstance.interceptors.response.use(this.onResponse.bind(this), this.onResponseError.bind(this));
    this.tokenIsValid.next(true);
  }

  private async request(parameters: HttpRequest): Promise<HttpResponse> {
    let axiosResponse: AxiosResponse;
    let success = true;
    let headers = {};

    if (this.hasEmbed && parameters.withCredentials !== false) {
      headers = {
        Authorization: `Bearer ${this.internalToken?.getToken() || ''}`,
      };
    }

    try {
      // debugger;
      axiosResponse = await this.axiosInstance.request({
        method: parameters.method,
        url: parameters.url,
        data: parameters.body || {},
        headers: Object.assign(parameters.headers || {}, headers),
        responseType: parameters.responseType || undefined,
        withCredentials: parameters.withCredentials === undefined ? !this.hasEmbed : parameters.withCredentials,
        onUploadProgress: parameters.onUploadProgress || undefined,
        timeout: parameters?.timeout || 60000,
        signal: parameters.signal || undefined,
      });
    } catch (error: any) {
      // if (axios.isCancel(error)) {
      //   console.log('Request canceled:', error.message);
      // }
      success = false;
      axiosResponse = error?.response;
    }

    return {
      statusCode: axiosResponse?.status || 999,
      body: axiosResponse?.data,
      headers: axiosResponse?.headers,
    };
  }

  private requestStream<T>(parameters: HttpRequest): BehaviorSubject<T[] | null> {
    const internalResponse = new BehaviorSubject<T[] | null>(null);

    fetch(parameters.url, {
      method: parameters.method,
      body: parameters.body || undefined,
      headers: parameters.headers,
      credentials: 'include',
      signal: AbortSignal.timeout(60000),
    })
      .then(async response => {
        if (response.status === 401) {
          await this.refreshToken();

          return this.requestStream<T>(parameters);
        }

        if (response.body == null) {
          return;
        }

        const reader = response.body.getReader();

        while (true) {
          const {done, value} = await reader.read();

          if (done) {
            break;
          }

          const text = new TextDecoder('utf-8').decode(value);

          const values = text
            .trim()
            .split('\n')
            .map((item: string) => {
              try {
                if (item.length > 0 && item.substring(0, 5) === 'data:') {
                  return JSON.parse(item.substring(5));
                } else if (item.length > 0 && item.substring(0, 1) === '{') {
                  return JSON.parse(item);
                }

                return null;
              } catch {
                return '';
              }
            })
            .filter((item: string | null) => !!item);

          internalResponse.next((internalResponse.value || [])?.concat(values));
        }
      })
      .catch(async err => {
        if (err.status === 401) {
          await this.refreshToken();

          return this.requestStream<T>(parameters);
        }

        if (err.AbortSignal) {
          return;
        }

        internalResponse.error(err);
      })
      .finally(() => {
        internalResponse.complete();
      });

    return internalResponse;
  }

  public async get(parameters: HttpRequest): Promise<HttpResponse> {
    return await this.request({...parameters, method: HttpMethods.get});
  }

  public getStream(parameters: HttpRequest): any {
    return this.requestStream({...parameters, method: HttpMethods.get});
  }

  public postStream(parameters: HttpRequest): any {
    return this.requestStream({...parameters, method: HttpMethods.post});
  }

  public async post(parameters: HttpRequest): Promise<HttpResponse> {
    return await this.request({...parameters, method: HttpMethods.post});
  }

  public async put(parameters: HttpRequest): Promise<HttpResponse> {
    return await this.request({...parameters, method: HttpMethods.put});
  }

  public async patch(parameters: HttpRequest): Promise<HttpResponse> {
    return await this.request({...parameters, method: HttpMethods.patch});
  }

  public async delete(parameters: HttpRequest): Promise<HttpResponse> {
    return await this.request({...parameters, method: HttpMethods.delete});
  }
}
