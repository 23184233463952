// import ReactGA from 'react-ga';
import {lazy, Suspense, useEffect} from 'react';
import {Routes, Route} from 'react-router-dom';
import {ThemeProvider} from 'styled-components';
import environment from '@src/Infra/Config';

// ReactGA.initialize(environment.googleAnalytics);

import HttpClient from '@Infra/Http';
import DataSource from '@Data/DataSource/FileSystem';
import UserUseCase from 'src/Domain/UseCases/User/index';
import UserDataUseCase from '@src/Domain/UseCases/UserData';
import Theme from '@src/ds/components/bosons/Theme';

const LazyLoader = lazy(() => import('src/ds/components/atoms/LazyLoader'));
const UserProvider = lazy(() => import('src/Data/Provider/UserProvider'));
const Main = lazy(() => import('src/Core/Factories/Main'));
const Embed = lazy(() => import('src/Core/Factories/Embed'));
const Authentication = lazy(() => import('./Authentication'));
const Auth = lazy(() => import('src/Core/Factories/FileSystem/Auth'));
const UserDataProvider = lazy(() => import('src/Data/Provider/UserDataProvider'));
const ShowcaseMedias = lazy(() => import('src/Core/Factories/Showcases/Medias'));

export default () => {
  useEffect(() => {
    if (!window.opener) {
      return;
    }

    window.addEventListener('message', (event: any) => {
      if (event && event.data && event.data.type === 'request-confirm-access') {
        sessionStorage.setItem('clientId', event.data.payload.clientId);
        sessionStorage.setItem('externalId', event.data.payload.externalId);
      }
    });

    window.addEventListener('beforeunload', () => {
      sessionStorage.removeItem('clientId');
      sessionStorage.removeItem('externalId');

      window.opener.postMessage(
        {
          type: 'confirm-access',
          payload: {
            status: 'closed',
          },
        },
        '*',
      );
    });
  }, []);

  return (
    <Suspense fallback={<LazyLoader />}>
      <Routes>
        <Route
          path="/auth/*"
          element={
            <ThemeProvider theme={Theme['light']}>
              <Authentication />
            </ThemeProvider>
          }
        />
        <Route
          path="/*"
          element={
            <UserDataProvider useCase={UserDataUseCase()}>
              <Main />
            </UserDataProvider>
          }
        />
        <Route
          path="/embed/*"
          element={
            <UserDataProvider useCase={UserDataUseCase()}>
              <UserProvider useCase={UserUseCase()}>
                <Routes>
                  <Route
                    path="/smart-player/auth/:token"
                    element={<Auth dataSource={new DataSource(new HttpClient())} />}
                  />
                  <Route path="/*" element={<Embed />} />
                </Routes>
              </UserProvider>
            </UserDataProvider>
          }
        />

        <Route
          path="/test-embed/*"
          element={
            <>
              <header
                style={{
                  backgroundColor: 'yellow',
                  width: '100%',
                  height: '100px',
                  margin: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                HEADER
              </header>
              <main
                style={{
                  width: '100%',
                  height: 'calc(100vh - 100px)',
                  margin: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <aside style={{backgroundColor: 'green', width: '300px', height: '100%'}}>ASIDE</aside>
                <div style={{backgroundColor: 'blue', width: 'calc(100vw - 300px)', height: 'calc(100vh - 100px)'}}>
                  <iframe
                    src="https://dev.scaleup.com.br/embed/smart-player/videos"
                    style={{width: '100%', height: '100%', border: 0}}></iframe>
                </div>
              </main>
            </>
          }
        />
        <Route path="smart-player/showcases/videos" element={<ShowcaseMedias />} />
      </Routes>
    </Suspense>
  );
};
