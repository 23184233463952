import {ReactNode, createContext, useContext, useMemo} from 'react';
import {UserUseCase} from '@Domain/UseCases/User';

export type User = {
  children: ReactNode;
  useCase: UserUseCase;
};

const UserContext = createContext<any>(null);

export function useUser(){
  return useContext(UserContext)
}

export default (props: User) => {
  const {children, useCase} = props;
  const {token, getToken, updateToken} = useCase;

  return useMemo(
    () => (
      <UserContext.Provider
        value={{
          token,
          getToken,
          updateToken,
        }}
      >
        {children}
      </UserContext.Provider>
    ),
    [children, token],
  );
};
