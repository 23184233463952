import {useState, useEffect} from 'react';
import {Balance} from '@src/Data/Model/Balance';

export interface UserUseCase {
  token: null | string;
  getToken: () => string | null;
  updateToken: (newToken: string) => void;
}

declare global {
  interface Window {
    sp: any;
  }
}

function UserUseCase(): UserUseCase {
  const [token, setToken] = useState<string | null>(null);

  function getToken() {
    return window.sp;
  }

  async function updateToken(newToken: string) {
    window.sp = newToken;
    setToken(newToken);
  }

  return {
    token,
    getToken,
    updateToken,
  };
}

export default UserUseCase;
