import {useState} from 'react';

type userInfoProps = {
  userInfo: {
    code: string;
    name: string;
    email: string;
    logo: string;
  };
  isAdministrator: boolean;
  byOperator: boolean;
  superUser: boolean;
  limitedResource: boolean;
  aiProductsType: string[];
};

type selectedUserAccount = {
  code: string;
  name: string;
  logo?: string;
};

type EmbeddedUser = {
  name: string;
  limitedResource: boolean;
  aiProductsType: string[];
  selectedUserAccount: selectedUserAccount;
};

export interface UserDataUseCase {
  code: string;
  name: string;
  email: string;
  isAdministrator: boolean;
  byOperator: boolean;
  superUser: boolean;
  aiProductsType: string[];
  logo: string;
  limitedResource: boolean;
  reloadToken: boolean;
  selectedUserAccount: selectedUserAccount | null;
  setReloadToken: (reloadToken: boolean) => void;
  setLogo: (logo: string) => void;
  setUserInfo: (param: userInfoProps & {selectedUserAccount: selectedUserAccount}) => void;
  setEmbedUserInfo: (params: EmbeddedUser) => void;
}

function UserDataUseCase(): UserDataUseCase {
  const [code, setCode] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [isAdministrator, setIsAdministrator] = useState<boolean>(false);
  const [byOperator, setByOperator] = useState<boolean>(false);
  const [superUser, setSuperUser] = useState<boolean>(false);
  const [aiProductsType, setAiProductsType] = useState<string[]>([]);
  const [logo, setLogo] = useState<string>('');
  const [limitedResource, setLimitedResource] = useState<boolean>(false);
  const [reloadToken, setReloadToken] = useState<boolean>(false);
  const [selectedUserAccount, setSelectedUserAccount] = useState<UserDataUseCase['selectedUserAccount']>(null);

  function setUserInfo(param: userInfoProps & {selectedUserAccount: selectedUserAccount}) {
    setCode(param.userInfo.code);
    setName(param.userInfo.name);
    setEmail(param.userInfo.email);
    setIsAdministrator(param.isAdministrator);
    setByOperator(param.byOperator);
    setSuperUser(param.superUser);
    setLogo(param.userInfo.logo);
    setAiProductsType(param.aiProductsType);
    setLimitedResource(param.limitedResource);

    setSelectedUserAccount(param.selectedUserAccount.code ? param.selectedUserAccount : null);
  }

  function setEmbedUserInfo(params: EmbeddedUser) {
    setName(params.name);
    setLimitedResource(params.limitedResource);
    setAiProductsType(params.aiProductsType);
  }

  return {
    code,
    name,
    email,
    isAdministrator,
    byOperator,
    superUser,
    aiProductsType,
    logo,
    limitedResource,
    reloadToken,
    selectedUserAccount,
    setReloadToken,
    setUserInfo,
    setLogo,
    setEmbedUserInfo,
  };
}

export default UserDataUseCase;
