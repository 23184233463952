import Dark from '@src/ds/components/bosons/Color/Dark';
import Light from '@src/ds/components/bosons/Color/Light';

export default {
  dark: {
    color: Dark,
  },
  light: {
    color: Light,
  },
};
