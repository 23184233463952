import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, RouterProvider, createBrowserRouter} from 'react-router-dom';
import Router from './Presentation';
import GlobalStyl from './Presentation/Global';
import reportWebVitals from './reportWebVitals';
import {init as initApm} from '@elastic/apm-rum';
import {initReactI18next, I18nextProvider} from 'react-i18next';
import i18next from 'i18next';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import ThemeProvider from './Data/Provider/ThemeProvider';
import UserThemeUseCase from './Domain/UseCases/UserTheme';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const apm = initApm({
  // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
  serviceName: 'admin-react',

  // Set custom APM Server URL (default: http://localhost:8200)
  serverUrl: 'https://apm.scaleup.com.br',

  // Set service version (required for sourcemap feature)
  serviceVersion: '',
});
const browserLang = (): string => {
  if (navigator.language === 'pt-BR') {
    return 'pt-BR';
  }
  if (navigator.language === 'es-ES') {
    return 'es';
  }
  return 'en';
};

i18next
  .use(ChainedBackend)
  .use(initReactI18next)
  .init({
    lng: browserLang(), // default language
    fallbackLng: 'en',
    defaultNS: 'translation', // default namespace, if you don't want to specify it in JS files every time.
    interpolation: {
      escapeValue: false,
    },
    backend: {
      backends: [
        HttpBackend, // if you need to check translation files from server
        resourcesToBackend((language: string, namespace: string) => {
          const namespacePath = namespace.replace('.', '/');
          return import(`./Translations/${namespacePath}/${language}.json`);
        }),
      ], // the most important part that allows you to lazy-load translations
      backendOptions: [
        {
          loadPath: 'https://stage-smartplayer.scaleup.com.br/locales/{{lng}}/{{ns}}.json',
        },
      ],
    },
  });

const router = createBrowserRouter([{path: '*', element: <Root />}]);

function Root() {
  return (
    <>
      <I18nextProvider i18n={i18next}>
        <GlobalStyl />
        <ThemeProvider useCase={UserThemeUseCase()}>
          <Router />
        </ThemeProvider>
      </I18nextProvider>
    </>
  );
}
root.render(<RouterProvider router={router} />);
// </React.StrictMode>

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
